<template>
    <div>
        <div class="commen-hader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2>REVIEWS</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="vacancies_area">
            <div class="container">
                <!-- Show ratings only for the product surplus creator -->
                <Ratings
                    v-if="user"
                    title="Ratings received from others"
                    :hideRatingButton="true"
                    :forUserId="user.id"
                    :materialTester="{}"
                />
                <Ratings
                    v-if="user"
                    title="My Ratings to sellers"
                    :hideRatingButton="true"
                    :byUserId="user.id"
                    :materialTester="{}"
                    :notIsMaterialTester="true"
                    :hideAverageRating="true"
                />
                <Ratings
                    v-if="user"
                    title="My Ratings to material testers"
                    :hideRatingButton="true"
                    :byUserId="user.id"
                    :materialTester="{}"
                    :isMaterialTester="true"
                    :hideAverageRating="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from "../_helper/http-constants"
import axios from "axios"
import scrollTop from "./utils/scrollTop"
import request from "../apis/request"
import { mapState } from "vuex"
import Ratings from "./Rating/Ratings.vue"

export default {
    name: "Reviews",

    data() {
        return {
            // imgsrc: 'http://103.212.120.205/~dev/public/admin_profile/',
            //   imgsrc: "http://localhost/backend/public/admin_profile/",

            imgsrc: axios.defaults.uploadURL,
        }
    },
    components: {
        Ratings,
    },
    computed: {
        ...mapState("auth", {
            user: "user",
        }),
    },
    mounted() {
        scrollTop()
        request.get("/job").then((res) => {
            this.user = res.data
        })
    },
}
</script>
